label{
font-family: Poppins;
font-size: 14px;
font-weight: 500;
letter-spacing: 0.001em;

}

 .select{
    width: 60px;
   height:30px;
 margin-left: 10px;
}
.selectdel{
    width: 60px;
   height:30px;
 margin-left: 5px;
}
.selectlast{
    width: 100px;
    height:30px;
    margin-left: 0;
}
  
.text{
width:50px;
height:30px;
margin-left: 15px;
}
.form-row{
    margin-top: 20px;;
    display: flex;
}
.form-row label{
    margin-left:40px;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (max-width:600px)
{
    .helper{
        margin-left: 20%;
    }
}
@media (max-width:600px)
{
    .check{
        margin-left: 0;
    }
}